import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionImageBkg } from "../../components/Section"
import { Link } from "gatsby"
import QuoteLeft from "../../images/quote-left.png"
import QuoteRight from "../../images/quote-right.png"
import ReviewPattern from "../../images/review-pattern.png"
import BlackButton from "../../components/Button/BlackButton"


const ViewReview = styled.div`    
    padding:90px 0 50px;
    position: relative; 
    width:100%;
    margin:0 auto; 
`

const ViewReviewBody = styled.div`
    text-align: center;
    margin: 0 auto 20px auto;
    position: relative;
    width:100%;
    padding: 40px 15px;
    max-width: 260px;
    @media(min-width:576px){
        max-width: 400px;
        padding: 40px 30px;
    }
    @media(min-width:768px){
        padding: 40px 45px;
        max-width: 550px;
    }
    @media(min-width:992px){
        padding: 40px 65px;
        max-width: 750px;
    }
    @media(min-width:1200px){
        max-width: 850px;
    }
    @media(min-width:1600px){
        max-width: 900px;
    }
    p{
        font-style:italic;
        color:#000;
        font-weight:500;
        font-size:16px;
        line-height:28px; 
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @media(min-width:992px){
            font-size:18px;
            line-height:32px; 
            
        }
        @media(min-width:1200px){
            font-size:20px;
            line-height:34px; 
        }
        @media(min-width:1600px){
            font-size: 24px;
            line-height: 48px;
        }
    }
    > span{
        display:block ;
        font-size:16px;
        line-height:20px;
        font-weight:600;
        color:#8D8D8D;
        letter-spacing:0.5px;
        strong{
            font-weight:600;
            color:#F8A24C;
            text-decoration:underline;
            text-underline-offset: 5px;
            text-decoration-thickness: 2px;

        }
    }   
    &:after, &:before{
        display:block;
        content:'';
		background-repeat:no-repeat;
        position:absolute;
        -webkit-filter: drop-shadow(0 0 20px #000);
        filter: drop-shadow(0 0 20px #000);
        width:36px;
		height:30px;
        background-size:90%;
        @media(min-width:992px){
            background-size:cover;
            width:56px;
            height:50px;

        }
    }
    &:before{
		top:0;
		left:0;
        background-image:${props => props.bgBefore};
	}
	&:after{
        bottom:0;
        right:0;
        background-image:${props => props.bgAfter};
	}
`
const ViewReviewFooter = styled.div`
    text-align:center;
    .btn{
        @media (min-width: 1600px){
            letter-spacing: 1.2px;
        }
    }
`
const SectionImageBkgs = styled(SectionImageBkg)`
    text-align:center;
    > .gatsby-image-wrapper {
        height: 100%;
        width: auto;
        display: inline-block;
        img{
            object-fit: fill !important;
            @media(min-width:992px){
                object-fit: cover !important;
            }
        }
    }
`


const ReviewSection = ({ data }) => {
	return(
		<Section pt="0" pb="0" bgColor="#fff" className="section-review" bgImg={`url(${ReviewPattern})`}>	
            <div className="container">
                <SectionImageBkgs><StaticImage src="../../images/review-bkg.png" alt="review-bkg" /></SectionImageBkgs>
                <ViewReview>
                    <ViewReviewBody bgBefore={`url(${QuoteLeft})`} bgAfter={`url(${QuoteRight})`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.reviewText.childMarkdownRemark.html,
                          }}
                        />
                        <span>{data.reviewer}</span>
                    </ViewReviewBody>
                    <ViewReviewFooter>
                        <Link to='/reviews'><BlackButton text="Read Our Reviews…" animate="ripple-black" /></Link>
                    </ViewReviewFooter>
                </ViewReview>
            </div>
		</Section>
	)
}
export default ReviewSection