import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Section, SectionTitle, SectionLead, SectionImageBkg } from "../../components/Section"
import { LineArrowRightIcon, ConfirmIcon, InstallIcon, ManufactureIcon, ReviewIcon } from "../../components/Icons"
import DefaultLinkButton from "../../components/Button/DefaultLinkButton"

const Sections = styled(Section)`
    background: #1383c1;
    background: linear-gradient(to bottom,  #1383c1 0%,#1383c1 44%,#00659f 100%);    

    margin-top: -60%;
    padding:50% 0 0;
    @media(min-width:576px){
        margin-top: -40%;
        padding:35% 0 0;
    }
    @media(min-width:768px){
        margin-top: -35%;
        padding:35% 0 0;
    }
    @media(min-width:992px){
        margin-top: -20%;
        padding:20% 0 0;
    }
    @media(min-width:1200px){
        margin-top: -20%;
        padding:25% 0 20%;
    }
`
const IntroFlex = styled.div`
    display:flex;
    flex-wrap:wrap;
    position: relative;
    z-index: 1;
    margin-bottom:30px;    
`
const IntroColumn = styled.div`
    position:relative;
    width:100%;
    @media(min-width:992px){
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    @media(min-width:1200px){
        margin-left:8.333333%;
    }
    .btn{
        color:#FED6B4;
        & .icon{
            > svg{
                fill:#FED6B4;
            }
        }
        &.btn-underline{
            .text{
                &:after{
                    background-color:#FED6B4;
                }
            }
        }
        &:hover{
            color:#E08932;
            & .icon{
                > svg{
                    fill:#E08932;
                }
            }
            &.btn-underline{
                .text{
                    &:after{
                        background-color:#E08932;
                    }
                }
            }
        }
    }
`



const ProcessStep = styled.div`
    position:relative;
    z-index: 1;
    left: 10px;
    @media(min-width:1200px){
        position: absolute;
        top: 0;
        right: 22%;
        left:auto;
    }
    @media(min-width:1440px){
        right: 30%;
    }
`
const ProcessStripDesktop = styled.div`
    display:none;
    @media(min-width:1200px){
        display:block;
    }
`
const ProcessStripMobile = styled.div`
    display:block;
    @media(min-width:1200px){
        display:none;
    }
    .gatsby-image-wrapper{
        display:inline-block;
    }

`
const ProcessStartPoint = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    @media(min-width:768px){
        font-size: 14px;
    }
    @media(min-width:1200px){
        position: absolute;
        top: -20px;
        right: -15px;
    }
`
const ProcessEndPoint = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    position: absolute;
    transform: rotate(30deg);
    left: 30px;
    @media(min-width:768px){
        font-size: 14px;
    }
    @media(min-width:1200px){
        bottom: -10px;
        left: 30%;
        transform: rotate(-45deg);

    }
`
const ProcessLabel = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 8%;
    left: 30px;
    @media(min-width:1200px){
        left: 100%;
        top: 12%;
    }
`
const ProcessItemLabel = styled.div`
    font-weight:700;
    color:#fff;
    text-transform:uppercase;
    cursor:pointer;
    position:relative;   
    text-align:center; 
    font-size:14px;
    line-height:28px;
    @media(min-width:1200px){
        font-size:18px;
        line-height:30px;
    }
    @media(min-width:1440px){
        font-size:26px;
        line-height:40px;
    }
    & .process-point{
        display: flex;
        align-items: self-start;
        & .circle{
            display:inline-block;
            position:relative;
            text-align:center;
            width:40px;
            height:40px;
            &:after, &:before{
                display:block;
                position:absolute;
                content:'';
                border-radius:50%;
            }
            &:after{
                width:14px;
                height:14px;
                background:#FDA036;
                top:50%;
                left:50%;
                transform:translate(-50%, -50%);
            }
            &:before{
                width:100%;
                height:100%;
                background:#0D496B;
                border:4px solid #FDA036;
                border-width:8px;
            }
        }
        & .text{
            display: flex;
            align-items: center;
            position: relative;
            background: rgba(0,0,0,0.3);
            border-radius: 12px;
            padding: 10px 15px;
            margin-left:25px;
            @media(min-width:1440px){
                border-radius: 15px;
                padding: 15px 30px;
                margin-left:35px;
            }
            &:after {
                right: 100%;
                top: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(172, 166, 213, 0);
                border-right-color:rgba(0,0,0,0.3);
                border-width: 15px;
                margin-top: -15px;                
            }
            > svg{
                margin-right:10px;
                fill:#fff;
                height:24px;
                @media(min-width:1440px){
                    height:initial;
                }
            }
        }

    }
    &:nth-child(1){
        top: 0;
        left: -40px;
        & .process-point{
            & .circle{
                &:after{background:#FDA036;}
                &:before{border-color:#FDA036;}
            }
        }
    }
    &:nth-child(2){
        top: 50px;
        left: -40px;
        @media(min-width:1200px){
            top: 65px;
            left: -85px;
        }
        @media(min-width:1440px){
            top: 45px;
            left: -85px;
        }
        & .process-point{
            & .circle{
                &:after{background:#27E3E3;}
                &:before{border-color:#27E3E3;}
            }
        }
    }
    &:nth-child(3){
        top: 95px;
        left: -40px;
        @media(min-width:1200px){
            top: 120px;
            left: -155px;
        }
        @media(min-width:1440px){
            top: 80px;
            left: -155px;
        }
        & .process-point{
            & .circle{
                &:after{background:#F8DA3C;}
                &:before{border-color:#F8DA3C;}
            }
        }
    }
    &:nth-child(4){
        top: 145px;
        left: -40px;
        @media(min-width:1200px){
            top: 165px;
            left: -265px;
        }
        @media(min-width:1440px){
            top: 110px;
            left: -260px;
        }
        & .process-point{
            & .circle{
                &:after{background:#40C106;}
                &:before{border-color:#40C106;}
            }
        }
    }
`

const InstallationSection = () => {
	return(
		<Sections pt="0" pb="0" className="section-instllation">	
            <SectionImageBkg opacity="0.1"><StaticImage src="../../images/inside-center-carport.jpg" alt="Inside Center Carport" /></SectionImageBkg>
            <div className="container">
                <IntroFlex>
                    <IntroColumn>
                        <SectionTitle color="#fff">Easy Installation Process</SectionTitle>
                        <SectionLead color="#fff" maxWidth="950px" ml="0">
                            <p>No need to worry about delivery and installation! We’ve created an excellent, time-tested process to ensure all of our customers receive prompt, kind, and courteous service in Florida. Please reach out to our office anytime you have a question, we’ll be glad to help! </p>
                        </SectionLead>
                        <Link to='/site-preparation'><DefaultLinkButton  iconAfter={<LineArrowRightIcon />} text="Learn About Installation…" decoration="underline" /></Link>
                    </IntroColumn>
                </IntroFlex>
                <ProcessStep>                    
                    <ProcessStartPoint>START</ProcessStartPoint>
                    <ProcessStripDesktop><StaticImage src="../../images/process-strip.png" alt="process-strip" /></ProcessStripDesktop>
                    <ProcessStripMobile><StaticImage src="../../images/process-strip-mobile.png" alt="process-strip-mobile" /></ProcessStripMobile>
                    <ProcessLabel>
                        <ProcessItemLabel>
                            <span className="process-point">
                                <span className="circle"></span> <span className="text"><ReviewIcon /> Review</span>
                            </span>
                        </ProcessItemLabel>
                        <ProcessItemLabel>
                            <span className="process-point">
                                <span className="circle"></span><span className="text"><ConfirmIcon /> Confirmation</span>
                            </span>
                        </ProcessItemLabel>
                        <ProcessItemLabel>
                            <span className="process-point">
                                <span className="circle"></span> <span className="text"><ManufactureIcon /> Manufacture</span>
                            </span>
                        </ProcessItemLabel>
                        <ProcessItemLabel>
                            <span className="process-point">
                                <span className="circle"></span> <span className="text"><InstallIcon /> Install</span>
                            </span>
                        </ProcessItemLabel>
                    </ProcessLabel>
                    <ProcessEndPoint>Your Building</ProcessEndPoint>
                </ProcessStep>
            </div>
		</Sections>
	)
}
export default InstallationSection