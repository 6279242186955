import React from "react"
import {  Section } from "../../components/Section"
import CategoryCarousel from "../../components/CategoryCarousel"

const CategorySection = ({ data }) => {
	return(
		<Section pt="0" pb="0" bgColor="#fff" className="section-category">			
      <CategoryCarousel data={data} />
		</Section>
	)
}


export default CategorySection
