import React from 'react'
import styled from "styled-components";
import { BaseButton } from '../Section'

const BlackBtn = styled(BaseButton)`
	background: #000;
	border-color:#000;
	color:#fff;
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		background: #E08932;
		border-color:#E08932;
		color:#fff;
		& .icon{
			> svg{
				fill:#fff;
			}
		}
	
	}
`

const BlackButton = (props) => {
	const { icon, iconAfter, text, size, animate } = props;
	return(
		<BlackBtn className={`btn ${size ? `btn-${size}` : ''} ${animate ? `btn-${animate}` : ''} `}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</BlackBtn>
	)
}

export default BlackButton