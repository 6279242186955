import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Section, SectionPageTitle, SectionLead } from "../../components/Section"
import DefaultButton from "../../components/Button/DefaultButton"
import GrayTexture from "../../images/gray-texture.png"

const Sections = styled(Section)`
    z-index:1;        
    background-size: cover;
    background-position:0 100%;
    background-repeat: no-repeat;
    padding:60px 0 200px;
    @media(min-width:768px){
        padding:100px 0 200px;
    }
    @media(min-width:992px){
        padding:110px 0 200px;
    }
    @media(min-width:1200px){
        padding:120px 0 200px;
	}
    @media(min-width:1440px){   
        padding:140px 0 200px;
    }
    @media(min-width:1600px){
        padding:180px 0 200px;
	}
    /* .container{
        @media(min-width:1650px){
            max-width:inherit;
            padding-left:calc((100% - 1650px )/2);
        }
    } */
    &:before {
        content: attr(data-shadowtext);
        top:0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;
        color: rgba(255, 255, 255, 0.1);
        text-transform: uppercase;
        font-weight: 900;
        z-index:1;
        display:block;
        font-size: 60px;
        line-height: 40px;
        @media (min-width:768px) {    
        font-size: 80px;
        line-height: 50px;
        }
        @media (min-width: 992px) {    
        font-size: 100px;
        line-height: 60px;
        }
        @media (min-width: 1200px) {    
        font-size: 130px;
        line-height: 80px;
        }
        @media (min-width: 1440px) {
        font-size: 190px;
        line-height: 110px;
        }
        @media (min-width: 1600px) {
        font-size: 260px;
        line-height: 160px;
        }
        @media (min-width: 1900px) {
        font-size: 290px;
        line-height: 180px;
        }
    }
`

const Flexbox = styled.div`
    position:relative;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    margin:0 -15px; 
    
`
const FlexboxLeft = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;

    @media (min-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media (min-width: 992px) {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
`
const FlexboxRight = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;

    @media (min-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media (min-width: 992px) {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .gatsby-image-wrapper{
        max-width: 375px;
        margin: 0 auto;
        display: block;
        @media (min-width: 768px) {
            max-width: initial;
            display:inline-block;
        }

    }
`

const AboutSystemSection = () => {
	return(
		<Sections pt="0" pb="0" bgColor="transparent" bgImg={`url(${GrayTexture})`} className="section-system" data-shadowtext="Welcome to">	
            <div className="container">
                <Flexbox>
                    <FlexboxLeft>
                        <SectionPageTitle className="h2" color="#fff">Tubular Building Systems </SectionPageTitle>
                        <SectionLead color="#fff" maxWidth="890px" ml="0" mb="40px">
                            <p>We are Florida’s leading manufacturer of commercial steel buildings, barndominiums, and risk category II habitable structures. Our Florida steel building prices include free delivery and installation! We manufacture and erect our own steel buildings. Our high-quality tubular steel framing and high-quality of workmanship are second to none. These steel structures prevent leaks, hold up to wear and tear, and are designed to withstand wind speeds of up to 170 MPH. We build everything from Steel Shops, to Hay Barns, to Equipment Buildings, to Warehouses, to Storage Buildings, RV Buildings, Pavilions, Sheds, Airplane Hangars, Boat Shelters, and Garages in Florida.</p>
                        </SectionLead>
                        <Link to='/about-us'><DefaultButton text="Learn More…" animate="ripple-white" /></Link>
                    </FlexboxLeft>
                    <FlexboxRight>
                        <StaticImage src="../../images/tbs-character.png" alt="TBS Character" />
                    </FlexboxRight>
                </Flexbox>
            </div>
		</Sections>
	)
}
export default AboutSystemSection