import React, { useState } from "react"
import {
    Accordions,
    Accordionsitems,
    AccordionsContent,
    AccordionsContentinner,
    AccordionsTitle,
  } from "../Accordion"



const FaqAccordion = ({ data }) => {    
    const [activeFaq, setActiveFaq] = useState(null)
    function handleAccodion(key) {
      if (activeFaq === key) {
        return setActiveFaq(null)
      }
      setActiveFaq(key)
    }
	return (
        <Accordions className="accordions">
            {data.map((item, index) => (
                <Accordionsitems
                className={`accordions-items ${activeFaq === index ? "active" : "" }`}
                key={index}
                >
                <AccordionsTitle className="accordions-title" onClick={() => handleAccodion(index)}>{item.node.question}<span></span></AccordionsTitle>
                <AccordionsContent className="accordions-content">
                    <AccordionsContentinner>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.node.answer.childMarkdownRemark.html,
                          }}
                        />
                    </AccordionsContentinner>
                </AccordionsContent>
                </Accordionsitems>
            ))}
        </Accordions>
	)
}

export default FaqAccordion
