import React, { Component } from 'react'
import styled, { keyframes } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image"
import { SectionHero } from "../../components/Section"
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"
import DefaultButton from "../../components/Button/DefaultButton"
import SearchBar from "../../components/SearchBar"
import BlueVector from "../../images/blue-pattern.jpg"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Ripple = keyframes`
     0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.1),
                0 0 0 10px rgba(0,0,0, 0.1);
	}
	100% {
		box-shadow: 0 0 0 10px rgba(0,0,0, 0.1),
					0 0 0 20px rgba(0,0,0, 0);
	}
`;
const SectionHeros = styled(SectionHero)`
	position:relative;
	.container{
		padding:0;
		@media (min-width: 1650px){
			max-width:inherit;
			padding-left: calc((100% - 1650px) / 2);
			padding-right: 0;
		}
	}
`
const HeroBanner = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;
	flex-direction: column-reverse;
	@media (min-width: 992px){
		flex-direction:row;
	}
`
const HeroColumn = styled.div`		
	width: 100%;
	@media (min-width: 992px){
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	@media (min-width: 1440px){
		flex: 0 0 25%;
		max-width: 25%;
  	}
`
const HeroSlideColumn = styled.div`
	position: relative;
	width: 100%;
	@media (min-width: 992px){
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	@media (min-width: 1440px){
		flex: 0 0 75%;
		max-width: 75%;
  	}
`
const HeroSlickSlider = styled.div`	
	height:100%;
`
const HeroSlickItem = styled.div`	
	> .gatsby-image-wrapper {
		display:inline-block;
		height:100%;
		width: 100%;
	}
`
const HeroItemNav = styled.div`	
	
`
const BlueCircleClipWrap = styled.div`	
	height: 100%;
	&:before{
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		z-index:1;
		background: rgba(255,255,255,0.25);		
		width:100%;
		height: 100%;
		clip-path:ellipse(100% calc(50% + 70px) at 50% 100%);
		@media (min-width: 576px){
			clip-path:ellipse(100% calc(35% + 70px) at 50% 100%);
		}
		@media (min-width: 576px){
			clip-path:ellipse(100% calc(32% + 70px) at 50% 100%);
		}
		@media (min-width: 992px){
			width: calc(100% + ((100vw + 1650px)/2));
			clip-path: circle(calc(100% + 70px) at 0 50%);
		}
		@media (min-width: 1440px){
			clip-path: circle(calc(90% + 70px) at 0 50%);
		}
	}
`
const BlueCircleClip = styled.div`
	height: 100%;
    display: flex;
    align-items: center;
	&:after, &:before{
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		z-index:1;
		width:100%;
		height: 100%;
		clip-path: ellipse(100% calc(50% + 30px) at 50% 100%);		
		@media (min-width: 576px){
			clip-path: ellipse(100% calc(35% + 30px) at 50% 100%);		
		}
		@media (min-width: 768px){
			clip-path: ellipse(100% calc(32% + 30px) at 50% 100%);		
		}
		@media (min-width: 992px){
			width: calc(100% + ((100vw + 1650px)/2));
			clip-path: circle(100% at 0 50%);
		}
		@media (min-width: 1440px){
			clip-path: circle(90% at 0 50%);
		}
	}
	&:before{
		background: -moz-linear-gradient(left,  rgba(19,131,193,0.9) 0%, rgba(0,110,172,0.9) 100%);
		background: -webkit-linear-gradient(left,  rgba(19,131,193,0.9) 0%,rgba(0,110,172,0.9) 100%);
		background: linear-gradient(to right,  rgba(19,131,193,0.9) 0%,rgba(0,110,172,0.9) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e61383c1', endColorstr='#e6006eac',GradientType=1 );
		z-index: 2;
	}
	&:after{
		background:${props => props.bgAfter};
		background-size: contain;
    	background-position: 0 0;
		background-position: center right;
		/* opacity: 0.15; */
	}	
`

const ImageCaption = styled.div`	
	color: #fff;
	position: relative;
    z-index: 2;
    width: 100%;
	text-align: center;
	margin:0 auto;
	padding: 30px 15px;
	@media (min-width: 768px){
		max-width: 670px;
	}
	@media (min-width: 992px){
		max-width: 440px;
		text-align: left;
		margin:0;
		padding: 30px 15px 90px 30px;
	}
	@media (min-width: 1200px){
		padding: 30px 15px 100px 30px;
	}
	@media (min-width: 1440px){
		padding: 50px 15px 100px 30px;
	}
	@media (min-width: 1600px){
		padding: 100px 15px 140px 30px;
	}
	@media (min-width: 1900px){
		padding: 160px 15px 230px 30px;
	}
	.slick-active {
		z-index: 10;
	}
`
const ImageCaptionTitle = styled.strong`
	display:block;	
	font-family: 'Teko', sans-serif;
	font-weight: 700;	
	color:#fff;
	margin:0;
	text-transform:uppercase;

	font-size: 40px;
	line-height: 40px;
	@media (min-width: 768px) {
		font-size: 50px;
		line-height: 50px;
	}
	@media (min-width: 992px) {
		font-size: 55px;
		line-height: 55px;
	}
	@media (min-width: 1200px) {
		font-size: 65px;
		line-height: 65px;
	}
	@media (min-width: 1600px) {
		font-size: 80px;
		line-height: 80px;
	}
	@media (min-width: 1900px) {
		font-size: 85px;
		line-height: 85px;
		font-weight:900;
	}
`
const ButtonToolbar = styled.div`	
	position: relative;
  width: 100%;
	margin:25px;

	@media(max-width: 992px) {
		margin: 25px auto;
	}
`
const HeroSearch = styled.div`
	position: relative;
	display:flex;
	align-items:center;	
	justify-content:space-between;
    z-index: 2;
	/* @media (max-width:991px){
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	} */
	@media (min-width:992px){			
		min-height: 78px;
		margin-top:-78px;
	}
    @media (min-width:1600px){
        min-height:100px;
        margin-top:-100px;
    }
    .search-bar{
        margin-left:0;
		@media (min-width:992px){			
			bottom:15px;
			max-width: 750px;
		}
		@media (min-width:1200px){
			max-width: 920px;
		}
        @media (min-width:1440px){
			max-width: 1130px;
        }
        @media (min-width:1600px){
            bottom:30px;
        }
    }
	.container{
		position: relative;
		width: 100%;
		margin:0 auto;
		padding:0;
		max-width: 1650px;
		@media (min-width: 992px) {
			padding:0 30px;
		}
	}
`

const SlickArrowTrack = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top:50%;
	transform:translateY(-50%);
	z-index: 1;
    width: 100%;	
	overflow:hidden;
	@media (min-width:992px){
		transform:none;
		top:auto;
		right: 30px;
		bottom: 30px;
		max-width: 160px;
		overflow:inherit;
	}
    @media (min-width:1600px){
		right: 10%;
		bottom: 60px;
    }
`
const LeftArrow = styled.button` 
	border-radius:50%;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	position:relative;
	width: 50px;
	height: 50px;
	background-color: rgba(0,0,0,0.8);
	left:-20px;
	@media(min-width:992px){
		left:0;
		background-color: rgba(0,0,0,0.4);
		animation: ${Ripple} 0.7s linear infinite;
	}
	@media(min-width:1440px){
		width: 60px;
		height: 60px;
	}
  svg{
    fill:#fff;
	width:12px;
	@media(max-width:991px){
		margin-left:14px;;
	}
	@media(min-width:1440px){
		width:16px;
	}
  }
  &:hover {
    background-color:#000;
  }
`
const RightArrow = styled(LeftArrow)`
	left:auto;
	right:-20px;
	svg{
		@media(max-width:991px){
			margin-right:14px;
			margin-left:0;
		}
	}
	@media(min-width:992px){
		right:0;
	}
`
function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button type='button' aria-label="button"
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowRightIcon/></button>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button type='button' aria-label="button"
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowLeftIcon/></button>
  );
}

export default class AsNavFor extends Component {
    constructor(props) {
      	super(props);
      	this.state = {
		  nav1: null,
		  nav2: null
		};
		this.next = this.next.bind(this);
	  	this.previous = this.previous.bind(this);
    }
  
    componentDidMount() {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
	next() {
		this.slider2.slickNext();
	}
	previous() {
		this.slider2.slickPrev();
	}
  render() {

  	const data = this.props.data

		const settingnav = {           
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  arrows:false,
		  fade: true,
		  speed: 200,
		};

		const settingfor = { 
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,       
		  nextArrow: <CircleNextArrow />,
			prevArrow: <CirclePrevArrow />,
		};
		return(
			<SectionHeros className="section-hero">
				<div className="container">
					<HeroBanner>
						<HeroColumn>
							<BlueCircleClipWrap>
								<BlueCircleClip bgAfter={`url(${BlueVector})`}>
									<ImageCaption>
										<Slider
											asNavFor={this.state.nav1}
											ref={slider => (this.slider2 = slider)}
											{...settingnav}
										>
											{
												data.map((item, i) => {
													return(
														<HeroItemNav key={i}>
															<ImageCaptionTitle>{item.title}</ImageCaptionTitle>
															{ item.buttonText && item.buttonLink && (
																<ButtonToolbar><a href={item.buttonLink} target="_blank"  rel="noreferrer" aria-label='button'><DefaultButton text={item.buttonText} animate="ripple-white" /></a></ButtonToolbar>
															)}
														</HeroItemNav>
													)
												})
											}
										</Slider>
									</ImageCaption>
								</BlueCircleClip>
							</BlueCircleClipWrap>
						</HeroColumn>
						<HeroSlideColumn>
							<HeroSlickSlider className="hero-slider">
								<Slider               
									asNavFor={this.state.nav2}
									ref={slider => (this.slider1 = slider)}
									arrows={false}
									{...settingfor}
								>
									{
										data.map((item, i) => {
											return(
												<HeroSlickItem key={i}>
													<GatsbyImage image={item.image.gatsbyImageData} alt={item.title} />
												</HeroSlickItem>
											)
										})
									}
								</Slider>
							</HeroSlickSlider>
						</HeroSlideColumn>
					</HeroBanner>
				</div>
				<HeroSearch>
					<div className="container">
						<SearchBar />
						<SlickArrowTrack>
							<LeftArrow onClick={this.previous}><ArrowLeftIcon /></LeftArrow>
							<RightArrow onClick={this.next}><ArrowRightIcon /></RightArrow>
						</SlickArrowTrack>
					</div>
				</HeroSearch>
			</SectionHeros>
        );
    }
}

