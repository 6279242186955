import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionImageBkg, SectionTitle} from "../../components/Section"
import { PhoneIcon, LineArrowRightIcon } from "../../components/Icons"
import { FormPrimary } from "../../components/Form"
import ContactForm from "../../components/ContactForm"
import BlueVector from "../../images/blue-pattern.jpg"
import FaqAccordion from "../../components/FaqAccordion"
import DefaultLinkButton from "../../components/Button/DefaultLinkButton"
import BreakpointUp from "../../components/Media/BreakpointUp"

const SectionFaq = styled(Section)`
	position:relative;	
`
const FaqRow = styled.div`
	position: relative;
    @media(min-width:992px){
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`
const FaqColLeft = styled.div`	
	position: relative;
    display:none;
    @media(min-width:992px){
        display:flex;
        padding: 0 calc(50vw - 810px) 0 0;  
    }
`
const FaqColRight = styled.div`	
	position: relative;
    @media(min-width:992px){
        padding: 0 calc(50vw - 810px) 0 0;  
    }
`
const HeroBackgroundShadow = styled.div`    
    padding-bottom:70px;
    @media (min-width: 992px) {
        padding-bottom:0;
    }
    &:before{
		content: "";
		position: absolute;
        width:100%;
		height:100%;
        top:0;
        left:0;
        background-color:rgba(255,255,255,0.2);

        clip-path: ellipse(100% 100% at 50% 0);
        @media (min-width: 992px) {
        clip-path: ellipse(calc(100% - 30px) calc(100% + 180px) at 100% 50%);
        }
	}
`
const HeroBackground = styled.div`    
    &:after, &:before{
		content: "";
		position: absolute;
        width:100%;
		height:100%;
        top:0;
        left:0;
        clip-path: ellipse(100% calc(100% - 60px) at 50% 0);
        @media (min-width: 992px) {
            clip-path: ellipse(calc(100% - 100px) calc(100% + 180px) at 100% 50%);
        }
	}
    &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
	}	
`



const FormSection = styled(FormPrimary)`     
    width:100%;
    padding: 40px 15px 30px;
    max-width: 520px;
    margin:0 auto;
    @media(min-width:992px){
        margin:0 0 0 auto;
        max-width:400px;
        padding: 60px 30px 30px;
    }
    @media(min-width:1200px){
        padding: 90px 30px 60px;
        max-width:480px;
    }
    @media(min-width:1440px){
        max-width:520px;
    }
    @media(min-width:1600px){
        max-width:590px;
        padding: 120px 30px 90px;
    }
    h2{
        @media(max-width:991px){
            text-align:center;
        }
    }
    .form-action{
        @media(max-width:991px){
            justify-content:center;
        }
    }

`

const FaqForm = styled.div`
    position:relative;
    .container{
        @media(min-width:1650px){
            max-width:inherit;
            padding-right:calc((100% - 1650px )/2);
        }
    }
`

const FaqCta = styled.div`
    @media (min-width: 992px) {
        background:#FFD6B4;
        background: linear-gradient(123deg, #FFD6B4 0%, #F8A24C 100%);
        padding: 40px 0;
     }
    @media (min-width: 1200px) {
        padding: 60px 0;
    }
    @media (min-width: 1600px) {
        padding: 80px 0;
    }
`

const FaqCtaRow = styled.div`
	display: flex;
	flex-wrap: wrap;
    margin:0 -15px;
    justify-content: flex-end;
`
const FaqCtaLeft = styled.div`	
    position: relative;
	width: 100%;
    padding:20px 15px;
    @media(min-width:992px){
        padding:0 15px;
        flex: 0 0 50%;
        max-width: 50%;
    }	
`
const FaqCtaRight = styled.div`	
    position: relative;
	width: 100%;    
    background: linear-gradient(123deg, #FFD6B4 0%, #F8A24C 100%);
    padding: 20px 15px;
    @media(min-width:768px){
        padding: 40px 15px;
    }
    @media(min-width:992px){
        flex: 0 0 50%;
        max-width: 50%;
        background:none;
        padding:0 15px;
    }	
`
const PhoneNumberCta = styled.div`
    small{
        display:block;
        font-family:"Teko", sans-serif;
        font-size:30px;
        font-weight:700;
        line-height:30px;
        color:rgba(0,0,0,0.28);
        text-transform:uppercase;
        @media (min-width: 1600px) {
            font-size:40px;
            line-height:40px;
        }
    }
    .cta-number{
        font-weight:700;
        color:#000;
        display:flex;
        align-items:center;
        font-size: 50px;
        line-height: 50px;
        @media (min-width: 768px) {
            font-size: 60px;
            line-height: 60px;
        }
        @media (min-width: 992px) {
            font-size: 70px;
            line-height: 70px;
        }
        @media (min-width: 1200px) {
            font-size: 80px;
            line-height: 80px;
        }
        @media (min-width: 1600px) {
            font-size: 90px;
            line-height: 80px;
        }
        .icon{
            display:flex;
            align-items:center;
            justify-content:center;
            > svg{
                height: 50px;
                width: 20px;
                transition: all .5s ease;
                @media (min-width: 768px) {
                    height: 50px;
                    width: 20px;
                }
                @media (min-width: 992px) {
                    height: 55px;
                    width: 25px;
                }
                @media (min-width: 1200px) {
                    height: 60px;
                    width: 30px;
                }
                @media (min-width: 1600px) {
                    height: 65px;
                    width: 35px;
                }
            }
            + .text{
                margin-left:10px;
            }
        }
        &:hover, &:focus{
            color:#1383C1;
            .icon{
                > svg{
                    fill:#1383C1;
                }
            }
        }
    }
`



const CardAccordion = styled.div`
    background:#fff;
    border-radius:20px;
    position:relative;
    overflow:hidden;
    width: 100%;
    @media (min-width: 992px) {
        max-width: 700px;
        margin-top: -500px;
    }
    @media (min-width: 1200px) {
        margin-top: -700px;
    }
    @media (min-width: 1600px) {
        margin-top: -900px;
    }
`

const CardAccordionHeading = styled.div`
    position:relative;
    padding:30px 15px 0;
    ${BreakpointUp.md`
        padding:30px 30px 0;
    `}
    ${BreakpointUp.xl`
        padding:40px 40px 0;
    `}
    ${BreakpointUp.xxl`
        padding:80px 80px 0;
    `}
`
const CardAccordionTitle = styled.h2`
    color:#000;
    text-transform:uppercase;
    margin-bottom:0;
    
`
const CardAccordionBody = styled.div`
    padding:0;
    .accordions-content{
        p{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }
`
const CardAccordionFooter = styled.div`
    position:relative;
    padding:15px 15px 30px;
    ${BreakpointUp.md`
        padding:20px 30px 30px;
    `}
    ${BreakpointUp.xl`
        padding:30px 40px 40px;
    `}
    ${BreakpointUp.xxl`
        padding:40px 80px 60px;
    `}
    .btn{
        padding:0 !important;
    }
`
const SectionImageBkgs = styled(SectionImageBkg)`
    border-radius:20px;
    overflow: hidden;
`

const FaqSection = ({ data, location }) => {
	return (
		<SectionFaq pt="0" pb="0" bgColor="#000"  className="section-faq">
                <FaqForm>
                    <SectionImageBkg opacity="0.15"><StaticImage src="../../images/inside-center-carport.jpg" alt="inside center carport" /></SectionImageBkg>
                    <FaqRow>
                        <FaqColLeft></FaqColLeft>
                        <FaqColRight>
                            <HeroBackgroundShadow>
                                <HeroBackground bgAfter={`url(${BlueVector})`}>
                                    <FormSection>
                                        <SectionTitle color="#fff">Fill the form to Contact Us!!</SectionTitle>
                                        <ContactForm location={location} />
                                    </FormSection>
                                </HeroBackground>
                            </HeroBackgroundShadow>
                        </FaqColRight>
                    </FaqRow>
                </FaqForm>


                <FaqCta>
                    <div className="container">		
                        <FaqCtaRow>
                            <FaqCtaLeft>
                                <CardAccordion>
                                   <SectionImageBkgs><StaticImage src="../../images/bubble-pattern.jpg" alt="bubble pattern" /></SectionImageBkgs>
                                    <CardAccordionHeading><CardAccordionTitle>Frequently asked questions?</CardAccordionTitle></CardAccordionHeading>    
                                    <CardAccordionBody><FaqAccordion data={data} /></CardAccordionBody>
                                    <CardAccordionFooter>
                                        <Link to='/faq'><DefaultLinkButton  iconAfter={<LineArrowRightIcon />} text="Read All FAQs…" decoration="underline" /></Link>
                                    </CardAccordionFooter>
                                </CardAccordion>
                            </FaqCtaLeft>
                            <FaqCtaRight>
                                <PhoneNumberCta>
                                    <small>Call us AT</small>
                                    <a href="tel:3869610006" aria-label="Phone" className="cta-number">
                                        <span className="icon"><PhoneIcon /></span>
                                        <span className="text">386-961-0006</span>
                                    </a>
                                </PhoneNumberCta>
                            </FaqCtaRight>
                        </FaqCtaRow>
                    </div>
                </FaqCta>
		</SectionFaq>
	)
}


export default FaqSection


