import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'


export const Accordions = styled.div`
  margin:0; 
`
export const Accordionsitems = styled.div`
  position:relative;
  border-bottom:1px solid rgba(0,0,0,0.2);
  padding:0 15px;
  ${BreakpointUp.md`
    padding:0 30px;
  `}
  ${BreakpointUp.xl`
    padding:0 40px;
  `}
  ${BreakpointUp.xxl`
    padding:0 80px;
  `}

  &.active{
    span{
      transform:rotate(45deg);
      color:#000;
      &:before,
      &:after{
        background:#000;
      }
    }
    .accordions-content{
      max-height:1000px;
      overflow:inherit;
      transition: max-height 0.2s ease-out;
    }
    &:before{
      opacity:1;
    }
  }
`
export const AccordionsContent = styled.div`
  max-height:0;
  overflow:hidden;
  transition: max-height .35s ease;
  position: relative;
  letter-spacing: 0.18px;
  color:#333;

  font-size:16px;
  line-height:30px; 
  ${BreakpointUp.xl`
    font-size:18px;
    line-height:32px; 
  `}
  ${BreakpointUp.xxl`
    font-size: 20px;
    line-height: 34px;
  `}
`
export const AccordionsContentinner = styled.div`
  padding:0px 0 20px;
`
export const AccordionsTitle = styled.div`
  cursor: pointer;
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:0;
  position:relative;
  z-index:1;
  color: #000;
  font-style:italic;
  font-weight: 500;
  padding:15px 0;
  font-size: 20px;
  line-height: 24px;
  ${BreakpointUp.md`
    font-size: 22px;
    line-height: 28px;
    padding:20px 0;
  `}
  ${BreakpointUp.xxl`
    font-size: 24px;
    line-height: 38px;
    padding:25px 0;
  `}

  span{
    flex: 0 0 14px;
    height:14px; 
    position:relative;
    display:inline-block;
    transition: all .35s ease;
    margin-left:40px;
    &:before,
    &:after{
      display:block;
      content:'';
      position:absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      background:#E08932;
    }
    &:before{
      width:4px;
      height:100%;
    }
    &:after{
      width:100%;
      height:4px;
    }
  }
`