import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section } from "../../components/Section"
import { PhoneIcon } from "../../components/Icons"
import DefaultButton from "../../components/Button/DefaultButton"
import BuildingBkg from "../../images/building-bkg.png"


const Sections = styled(Section)`
    filter: drop-shadow(0 0 10px #000);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -20%;
    padding-top: 30%;  
    background-size: 200% 100%;
    @media(min-width:768px){
        background-size: 100% 100%;
        margin-top: -15%;
        padding-top: 20%;  
    }
    @media(min-width:992px){
        margin-top: -12%;
        padding-top: 18%;  
    }
    @media(min-width:1200px){
        margin-top: -12%;
        padding-top: 15%;  
    }
    .container{
        @media(min-width:1650px){
            max-width:inherit;
            padding-left:calc((100% - 1650px )/2);
        }
    }
`

const Flexbox = styled.div`
    position:relative;
    display:flex;
    flex-wrap:wrap;
    margin:0 -15px;
    align-items: self-end;
`
const FlexboxLeft = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    @media(min-width:768px){
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
`
const FlexboxRight = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    display: flex;
    justify-content: center;
    @media(min-width:768px){
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
    .gatsby-image-wrapper{
        display:inline-block;
    }

`
const TextGradient = styled.div`
    padding-bottom:40px;
    text-align:center;
    @media(min-width:768px){
        padding-bottom:60px;
        text-align:left;
    }
`
const TextLabel = styled.div`
    color:#fff !important;
    text-transform:uppercase;
    font-size: 50px;
    line-height: 50px;
    @media (min-width: 992px) {
        font-size: 60px;
        line-height: 60px;
    }    
`

const StateText = styled.div`
    color: #fff !important;
    text-transform:uppercase;
    margin-bottom:30px;
    font-size: 65px;
    line-height: 65px;
    @media (min-width: 992px) {
        font-size: 75px;
        line-height: 75px;
    } 
`

const TextTitleGradient = styled.div`
    display:block;
    background: linear-gradient(to right, #FFD6B4 0%, #F8A24C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-family: 'Teko', sans-serif;
    font-weight: 700;

    

    font-size: 105px;
    line-height: 80px;
    @media (min-width: 768px) {
        font-size: 115px;
        line-height: 90px;
    }
    @media (min-width: 992px) {
        font-size: 125px;
        line-height: 100px;
    }
    @media (min-width: 1200px) {
        font-size: 135px;
        line-height: 110px;
    }
    @media (min-width: 1600px) {
        font-size: 145px;
        line-height: 120px;
    }
`
const TextSubTitleGradient = styled(TextTitleGradient)` 
    margin-bottom:10px;  

    
    font-size: 65px;
    line-height: 55px;
    @media (min-width: 768px) {
        font-size: 70px;
        line-height: 60px;
    }
    @media (min-width: 992px) {
        font-size: 80px;
        line-height: 65px;
    }
    @media (min-width: 1200px) {
        font-size: 85px;
        line-height: 70px;
    }
    @media (min-width: 1600px) {
        font-size: 90px;
        line-height: 75px;
    }
`


const QualitySection = () => {
	return(
		<Sections pt="0" pb="0" bgColor="transparent" bgImg={`url(${BuildingBkg})`} className="section-quality">	           
            <div className="container">
                <Flexbox>
                    <FlexboxLeft>
                        <TextGradient>
                            <TextLabel className="h2">Best Quality</TextLabel>
                            <TextTitleGradient >METAL</TextTitleGradient>
                            <TextSubTitleGradient>Buildings</TextSubTitleGradient>
                            <StateText className="h1">in Florida</StateText>
                            <a href="tel:3869610006" aria-label="Phone"><DefaultButton icon={<PhoneIcon />} text="386-961-0006" size="lg" animate="ripple-white" /></a>
                        </TextGradient>
                    </FlexboxLeft>
                    <FlexboxRight>
                        <StaticImage src="../../images/building.png" alt="TBS Character" />
                    </FlexboxRight>
                </Flexbox>
            </div>
		</Sections>
	)
}
export default QualitySection
