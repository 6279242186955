import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../sections/Home/HeroSection"
import AboutSystemSection from "../sections/Home/AboutSystemSection"
import InstallationSection from "../sections/Home/InstallationSection"
import QualitySection from "../sections/Home/QualitySection"
import ReviewSection from "../sections/Home/ReviewSection"
import FaqSection from "../sections/Home/FaqSection"
import CategorySection from "../sections/Home/CategorySection"


const IndexPage = ({ data, location }) => {
  const pageData = data.contentfulHomePage
  const faqData = data.allContentfulFaq.edges
  const productData = data.allContentfulProduct.edges
  const latestReview = data.allContentfulReview.edges[0].node
  return(
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <HeroSection data={pageData.heroSlider} />
      <AboutSystemSection />
      <InstallationSection />
      <QualitySection />
      <ReviewSection data={latestReview} />
      <FaqSection data={faqData} location={location} />
      <CategorySection data={productData} />
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query HomePageQuery{
    contentfulHomePage {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroSlider {
        title
        image {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
        buttonText
        buttonLink
      }
      categorySectionTitle
    }
    allContentfulFaq(limit: 6, sort: {order: ASC, fields: weight}) {
      edges{
        node{
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulProduct(sort: {fields: skuNumber}) {
      edges {
        node {
          sku
          cardImage {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
          }
          category {
            name
          }
        }
      }
    }
    allContentfulReview(limit: 1, sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          reviewer
          createdAt(formatString: "DD MMMM YYYY")
          reviewText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
