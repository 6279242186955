import React, { useState, useRef } from 'react'
import styled, { keyframes } from "styled-components";

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import {  SectionImageBkg, SectionTitle } from "../Section"
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"

const Ripple = keyframes`
     0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.1),
                0 0 0 10px rgba(0,0,0, 0.1);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(0,0,0, 0.1),
                    0 0 0 20px rgba(0,0,0, 0);
    }
`;

const CategoryCarousel = styled.div`
    position: relative;
`
const CategoryCarouselTop = styled.div`
    background-color:#000;
    position: relative;
    padding:30px 0 0;
    @media (min-width: 1200px) {
        padding:60px 0 0;
    }
    @media (min-width: 1600px) {
        padding:80px 0 0;
    }
`
const CategoryCarouselBottom = styled.div`
    position: relative;
    background-color:#fff;
    padding:40px 0;
    @media (min-width: 1200px) { 
        padding:60px 0;
    }
    @media (min-width: 1600px) {
        padding:80px 0;
    }
`
const CategoryThumbView = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin:0 -5px;
    @media (max-width: 575.98px) { 
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }
    @media (min-width: 768px) { 
        margin:0 -10px;
    }
    @media (min-width: 992px) { 
        margin:0 -15px;
    }
`
const CategoryThumbViewItem = styled.div`
    position: relative;
    width: 100%;
    padding:0 5px 30px 5px;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    @media (min-width: 576px) { 
        flex: 0 0 20%;
        max-width: 20%;
    }
    @media (min-width: 768px) { 
        padding:0 10px 40px 10px;
    }
    @media (min-width: 992px) { 
        padding:0 15px;
        padding-bottom:60px;
    }
    @media (min-width: 1200px) { 
        padding-bottom:80px;
    }
    @media (min-width: 1600px) {
        padding-bottom:100px;
    }
    &:after {
	    pointer-events: none;
        position: absolute;
        pointer-events: none;
        content: '';
        border-style: solid;
        border-color: transparent transparent transparent transparent;
        opacity: 0;
        visibility: hidden;
        transition: cubic-bezier(0.4,0,1,1) 0.6s all;
        top:calc(100% + 1px);
        left: calc(50% - 15px);
        border-width: 0 15px 15px 15px;
        @media (min-width: 992px) { 
            left: calc(50% - 25px);
            border-width: 0 25px 25px 25px;
        }
    }

    &.current{
        &:after {
            transform: translateY(-15px);
            @media (min-width: 992px) { 
                transform: translateY(-25px);
            }
            border-color: transparent transparent #fff transparent;
            opacity: 1;
            visibility: visible;
        }
        .media{
            .media-figure{
                background-color:#E08932;  
            }
            .media-title{
                color:#E08932;
            }
        }
        

    }
`

const CategoryThumb = styled.div`
    margin:0;
`
const CategoryFigure = styled.div`
    overflow:hidden;   
    padding:5px;
    margin-bottom:10px;
    border-radius:15px;
    transition:all 0.5s ease-in-out;
    @media (min-width: 992px) {   
        margin-bottom:20px;
    }
    .gatsby-image-wrapper{
        img{
            border-radius:10px;           
        }
    }
`
const CategoryThumbTitle = styled.div`
    display:block;
    text-align:center;
    text-transform: uppercase;
    color:rgba(255,255,255,0.4);
    font-family: 'Teko', sans-serif;
    font-size: 18px;
    line-height: 18px;
    font-weight:600;
    @media (min-width: 768px) {
        font-size: 20px;
        line-height: 20px;
    }
    @media (min-width: 992px) {
        font-size: 30px;
        line-height: 30px;
        font-weight:700;
    }
    @media (min-width: 1200px) {
        font-size: 40px;
        line-height: 40px;
    }
    @media (min-width: 1600px) {
        font-size: 50px;
        line-height: 50px;
    }
`

const SlickFullView = styled.div`
`
const SlickFullViewItem = styled.div`
    outline: none;
    padding:0 10px;
    position: relative;

    .sku-number {
        position: absolute;
        top: 20px;
        left: 10px;
        background-color: #000;
        color: #fff;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        padding: 5px 7px;
        border-radius: 0 4px 4px 0;
        z-index: 2;
    }
`
const ProductImage = styled.div`
    position: relative;
    z-index: 1;
    overflow:hidden;
    > .gatsby-image-wrapper{
        display:block;
        width:100%;
        img{
            border-radius:20px;
        }
    }
`

const SlickArrowTrack = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    z-index: 1;
    width: 100%;    
    overflow:hidden;
    @media (min-width:992px){
        transform:none;
        top:auto;
        right: 30px;
        bottom: 30px;
        max-width: 160px;
        overflow:inherit;
    }
    @media (min-width:1600px){
        right: 10%;
        bottom: 60px;
    }
`

const LeftArrow = styled.button` 
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    position:relative;
    width: 50px;
    height: 50px;
    background-color: rgba(0,0,0,0.8);
    left:-20px;
    @media(min-width:992px){
        left:0;
        background-color: rgba(0,0,0,0.4);
        animation: ${Ripple} 0.7s linear infinite;
    }
    @media(min-width:1440px){
        width: 60px;
        height: 60px;
    }
  svg{
    fill:#fff;
    width:12px;
    @media(max-width:991px){
        margin-left:14px;;
    }
    @media(min-width:1440px){
        width:16px;
    }
  }
  &:hover {
    background-color:#000;
  }
`
const RightArrow = styled(LeftArrow)`
    left:auto;
    right:-20px;
    svg{
        @media(max-width:991px){
            margin-right:14px;
            margin-left:0;
        }
    }
    @media(min-width:992px){
        right:0;
    }
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button type='button' aria-label="button"
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowRightIcon/></button>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button type='button' aria-label="button"
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowLeftIcon/></button>
  );
}

const CategoryTabCarousel = ({ data }) => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        centerMode: true,
        centerPadding: '0',            
        infinite: true,
        focusOnSelect: true,
        arrows: false,
        nextArrow: <CircleNextArrow />,
        prevArrow: <CirclePrevArrow />,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 567,
            settings: {
              slidesToShow: 1,
              centerPadding: '60px',
            }
          }
        ]  
    };

    const [currentTab, setCurrentTab] = useState("");

    const sliderRef = useRef(null);

    const next = () => {
        sliderRef.current.slickNext();
    }
    const previous = () => {
        sliderRef.current.slickPrev();
    }

    return (
        <CategoryCarousel>
            <CategoryCarouselTop>  
                <SectionImageBkg opacity="0.15">
                    <StaticImage src="../../images/inside-center-carport.jpg" alt="inside center carport" />
                </SectionImageBkg>
                <div className='container'>
                    <SectionTitle color="#fff" textAlign="left">What do you want to design today?</SectionTitle>
                    <CategoryThumbView>
                        <CategoryThumbViewItem onClick={() => setCurrentTab("Carport")} className={currentTab === 'Carport' ? 'current': ''}>
                            <CategoryThumb className='media'>
                                <CategoryFigure className='media-figure'><StaticImage src="../../images/carport-thumb.jpg" alt="Carport"/></CategoryFigure>
                                <CategoryThumbTitle>Carport</CategoryThumbTitle>
                            </CategoryThumb>
                        </CategoryThumbViewItem>
                        
                        <CategoryThumbViewItem  onClick={() => setCurrentTab("Garage")} className={currentTab === 'Garage' ? 'current': ''}>
                            <CategoryThumb className='media'>
                                <CategoryFigure className='media-figure'><StaticImage src="../../images/garage-thumb.jpg" alt="Garages"/></CategoryFigure>
                                <CategoryThumbTitle className='media-title'>Garages</CategoryThumbTitle>
                            </CategoryThumb>
                        </CategoryThumbViewItem>
                        
                        <CategoryThumbViewItem onClick={() => setCurrentTab("Commercial")} className={currentTab === 'Commercial' ? 'current': ''}>
                            <CategoryThumb className='media'>
                                <CategoryFigure className='media-figure'><StaticImage src="../../images/commercial-thumb.jpg" alt="Commercial"/></CategoryFigure>
                                <CategoryThumbTitle>Commercial</CategoryThumbTitle>
                            </CategoryThumb>
                        </CategoryThumbViewItem>
                        
                        <CategoryThumbViewItem onClick={() => setCurrentTab("Barns")} className={currentTab === 'Barns' ? 'current': ''}>
                            <CategoryThumb className='media'>
                                <CategoryFigure className='media-figure'><StaticImage src="../../images/barn-thumb.jpg" alt="Barns"/></CategoryFigure>
                                <CategoryThumbTitle>Barns</CategoryThumbTitle>
                            </CategoryThumb>
                        </CategoryThumbViewItem>
                        
                        <CategoryThumbViewItem onClick={() => setCurrentTab("Utilities")} className={currentTab === 'Utilities' ? 'current': ''}>
                            <CategoryThumb className='media'>
                                <CategoryFigure className='media-figure'><StaticImage src="../../images/utilities-thumb.jpg" alt="Utilities"/></CategoryFigure>
                                <CategoryThumbTitle>Utilities</CategoryThumbTitle>
                            </CategoryThumb>
                        </CategoryThumbViewItem>
                    </CategoryThumbView>
                </div>
            </CategoryCarouselTop>
            {currentTab !== "" && (
                <CategoryCarouselBottom>
                    <SectionImageBkg opacity="0.8">
                        <StaticImage src="../../images/bubble-pattern.jpg" alt="Bubble Pattern" />
                    </SectionImageBkg>
                    <SlickFullView>
                        <Slider {...settings} ref={sliderRef}>
                            {
                                data.map((item) => {
                                    if(item.node.category.name.indexOf(currentTab) > -1){
                                        return(
                                            <SlickFullViewItem>
                                                <div className="sku-number">
                                                    {item.node.sku}
                                                </div>
                                                <ProductImage><GatsbyImage image={item.node.cardImage.gatsbyImageData} alt={item.node.sku} /></ProductImage>
                                            </SlickFullViewItem>
                                        )
                                    } else {
                                        return true;
                                    }
                                })
                            }
                        </Slider>
                    </SlickFullView>
                    <SlickArrowTrack>
                        <LeftArrow onClick={() => previous()}><ArrowLeftIcon /></LeftArrow>
                        <RightArrow onClick={() => next()}><ArrowRightIcon /></RightArrow>
                    </SlickArrowTrack>
                </CategoryCarouselBottom>
            )}
            
        </CategoryCarousel>
    );
}

export default CategoryTabCarousel;